import React from "react";
import Layout from "../components/layout/Layout";

const NotFoundPage = () =>{

	const meta = {
		headline: `404 Not found`,
		lang: `en`,
		title: `404 Not found: SALTO KS | Cloud-Based Smart Locks, Wireless Access Control`,
		description: `The page you were looking for was not found`,
		full_link: ``,
		no_schema: true
	};
	return (
		<Layout meta={meta}>
			<h1 className="mt-5">NOT FOUND</h1>
			<p>The page you tried to access doesn't exist. <a href="https://saltoks.com">Click here</a> to go back.</p>
		</Layout>
	);
};

export default NotFoundPage;
